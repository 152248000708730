var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-12 col-sm-12 col-xs-12"},[_c('b-card',{attrs:{"title":"User Information"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"role","options":_vm.roleOptions,"name":"role","clearable":false,"searchable":false,"label":"text","reduce":function (role) { return role.value; }},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.user.role === 'agent')?_c('b-form-group',{attrs:{"label":"MLS Agent ID","label-for":"mls-id"}},[_c('validation-provider',{attrs:{"name":"MLS ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"mls-id","state":errors.length > 0 ? false:null,"name":"mls-id"},model:{value:(_vm.user.mlsId),callback:function ($$v) {_vm.$set(_vm.user, "mlsId", $$v)},expression:"user.mlsId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.user.role === 'agent')?_c('b-form-group',{attrs:{"label":"Office Name","label-for":"office-name"}},[_c('validation-provider',{attrs:{"name":"Office Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"officeName","filterable":false,"options":_vm.officeOptions},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"option",fn:function(officeOptions){return [_c('div',{staticClass:"d-center"},[_c('p',[_vm._v(_vm._s(officeOptions.officeName))]),_c('p',[_vm._v(_vm._s(officeOptions.officeShortName)+" | "+_vm._s(officeOptions.officePhone))])])]}},{key:"selected-option",fn:function(officeOptions){return [_c('div',{staticClass:"selected d-center"},[_c('p',[_vm._v(_vm._s(officeOptions.officeName))]),_c('p',[_vm._v(_vm._s(officeOptions.officeShortName)+" | "+_vm._s(officeOptions.officePhone))])])]}}],null,true),model:{value:(_vm.user.office),callback:function ($$v) {_vm.$set(_vm.user, "office", $$v)},expression:"user.office"}},[_c('template',{slot:"no-options"},[_vm._v(" type to search for offices ")])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone"},on:{"input":function($event){return _vm.formatPhoneNumber(_vm.user.phone)}},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"value":"1"},model:{value:(_vm.user.isAdmin),callback:function ($$v) {_vm.$set(_vm.user, "isAdmin", $$v)},expression:"user.isAdmin"}},[_vm._v(" Is Admin ")])],1),_c('b-button',{staticClass:"mb-2",attrs:{"variant":"outline-primary","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }